import { CustomTooltip, Label } from '@control-tower/aerq-ui-library'
import { ExternalLinkIcon } from '@control-tower/aerq-ui-library/dist/assets/svg'
import { TableBody, TableCell, TableRow } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useLazyGetFlightRuleManifestQuery, useUpsertFlightRuleManifestMutation } from 'services/ruleManifestApi'
import { ResolveAction } from 'types/enums/SurveyConflict'
import { SurveyTimeTriggers } from 'types/enums/SurveyTimeTriggers'
import { ISurveyDto } from 'types/interfaces/ISurveyDto'
import { IDetailedConflictMap } from 'types/interfaces/SurveyConflict'
import { customSuccessSnackbar } from 'utils/customSnackbarContent'

import { IConfirmationModalContentProps } from './ConfirmationModal'
import { ConflictResolutionErrorLabel } from './ConflictResolutionErrorLabel'
import { TSurveyErrorMap } from './RuleManifestConflictResolutionModal'
import styles from './RuleManifestConflictResolutionModal.module.css'

export type TConfirmationAction = () => Promise<void>
export interface ITableData {
    surveyId: string
    description: JSX.Element
    action: JSX.Element
}

export const ConflictTable = ({
    usedConflicts,
    archiveSurvey,
    setConfirmationModalErrorMessage,
    setConfirmationModalOpen,
    setConflictResolvedCounter,
    setSurveyErrorMap,
    conflictResolvedCounter,
    surveyErrorMap,
    setConfirmationModalProps,
    refreshConflicts,
    onConfirmationActionsDeclared,
}: IConflictTable) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [updateFlightRule] = useUpsertFlightRuleManifestMutation()
    const [getFlightRuleManifest] = useLazyGetFlightRuleManifestQuery()
    const [tableData, setTableData] = useState<ITableData[]>([])

    const handleArchiveSubmit = async (surveyId: string) => {
        await archiveSurvey(surveyId)
        enqueueSnackbar(t('success.archiveSuccess'), {
            content: customSuccessSnackbar,
        })
    }

    const handleFlightRuleConflict = async (surveyId: string, flightRulesToRemove: string[]) => {
        const existingFlightRules = await getFlightRuleManifest({ surveyId })
        const optimalFlightNumbers =
            existingFlightRules?.data?.flightNumbers?.filter((oldRule) => !flightRulesToRemove.includes(oldRule)) || []

        if (!optimalFlightNumbers || !optimalFlightNumbers.length) {
            console.error('Could not update flight Rules with empty optimalFlightNumbers')
            throw new Error('Could not update flight Rules with empty optimalFlightNumbers')
        }
        try {
            const response = await updateFlightRule({
                surveyId,
                flightNumbers: optimalFlightNumbers,
                timeTrigger: existingFlightRules?.data?.timeTrigger || SurveyTimeTriggers.minute30,
            })
            // @ts-ignore
            if (response?.error) {
                // @ts-ignore
                const error = { ...new Error(), ...response.error }
                throw error
            }
        } catch (e) {
            console.error('Could not update flight Rules', e)
            throw e
        }
    }

    useEffect(() => {
        let tempTableData: { surveyId: string; description: JSX.Element; action: JSX.Element }[] = []
        let tempConfirmationActions: TConfirmationAction[] = []
        if (usedConflicts?.surveyIdToFlightNumberMap && Object.keys(usedConflicts?.surveyIdToFlightNumberMap).length) {
            tempTableData = Object.keys(usedConflicts.surveyIdToFlightNumberMap).map((surveyId) => {
                const flightNumbers: string[] = usedConflicts.surveyIdToFlightNumberMap[surveyId]
                const surveyName = usedConflicts.surveyData[surveyId]?.name || ''
                const action: ResolveAction = usedConflicts.surveyConflictResolutionMap[surveyId]
                let actionButton
                let conflictMessage
                switch (action) {
                    case ResolveAction.ARCHIVE: {
                        conflictMessage = t('conflictResolutionModal.conflictOnAllFlightNumbers')
                        const confirmationAction = async () => {
                            try {
                                await handleArchiveSubmit(surveyId)
                                try {
                                    await refreshConflicts()
                                } catch (e) {
                                    console.error('refresh conflicts error:', e)
                                }
                                setConflictResolvedCounter(conflictResolvedCounter + 1)
                                setConfirmationModalErrorMessage('')
                                setConfirmationModalOpen(false)
                            } catch (e: any) {
                                let errorMessage = t('conflictResolutionModal.unexpectedErrorMessage')

                                if (e.status === 400) {
                                    errorMessage = t('conflictResolutionModal.400ErrorMessage')
                                }

                                const errorLabel = <ConflictResolutionErrorLabel errorMessage={errorMessage} />
                                setSurveyErrorMap({ ...surveyErrorMap, ...{ [`${surveyId}`]: errorLabel } })
                                setConfirmationModalErrorMessage(errorMessage)
                            }
                        }
                        tempConfirmationActions.push(confirmationAction)
                        actionButton = (
                            <Label
                                type="gradient"
                                color="main"
                                variant="small"
                                className={styles.clickableLabel}
                                onClick={async () => {
                                    const confirmationModalProps: IConfirmationModalContentProps = {
                                        title: `${t('conflictResolutionModal.archiveConfirmationQuestion')} "${surveyName}" ?`,
                                        description: ``,
                                        confirmationActionLabel: t('conflictResolutionModal.archiveConfirmationLabel'),
                                        confirmationAction: confirmationAction,
                                    }

                                    setConfirmationModalProps(confirmationModalProps)
                                    setConfirmationModalOpen(true)
                                }}
                            >
                                {t('conflictResolutionModal.archiveSurveyLabel')}
                            </Label>
                        )
                        break
                    }
                    case ResolveAction.MODIFY: {
                        conflictMessage = t('conflictResolutionModal.conflictingFlightNumbers')
                        const confirmationAction = async () => {
                            try {
                                await handleFlightRuleConflict(surveyId, flightNumbers)
                                try {
                                    await refreshConflicts()
                                } catch (e) {
                                    console.error('refresh conflicts error:', e)
                                }

                                setConflictResolvedCounter(conflictResolvedCounter + 1)
                                setConfirmationModalErrorMessage('')
                                setConfirmationModalOpen(false)
                            } catch (e: any) {
                                const errorMessage =
                                    e?.status === 409 ? (
                                        <>
                                            {t('conflictResolutionModal.conflictsCouldNotBeResolvedDueExternalConflicts')}
                                            <Link to={`/edit-survey/${surveyId}`} target={'_blank'} className={styles.goToSurveyLink}>
                                                <Label
                                                    title={
                                                        <>
                                                            {t('conflictResolutionModal.goToSurvey')}
                                                            <ExternalLinkIcon />
                                                        </>
                                                    }
                                                    variant="small"
                                                    fontWeight="Light"
                                                />
                                            </Link>{' '}
                                            {t('conflictResolutionModal.toResolveConflicts')}
                                        </>
                                    ) : (
                                        t('conflictResolutionModal.unexpectedErrorMessage')
                                    )
                                const errorLabel = <ConflictResolutionErrorLabel errorMessage={errorMessage} />
                                setSurveyErrorMap({ ...surveyErrorMap, ...{ [`${surveyId}`]: errorLabel } })
                                setConfirmationModalErrorMessage(errorMessage)
                            }
                        }
                        tempConfirmationActions.push(confirmationAction)
                        actionButton = (
                            <>
                                <Label
                                    type="gradient"
                                    color="main"
                                    variant="small"
                                    className={styles.clickableLabel}
                                    onClick={async () => {
                                        const confirmationModalProps: IConfirmationModalContentProps = {
                                            title: `${t('conflictResolutionModal.areYouSureToRemoveFlightNumbers')} "${surveyName}" ?`,
                                            description: `${t('conflictResolutionModal.listOfFlightNumbers')} ${flightNumbers.join(', ')}`,
                                            confirmationActionLabel: t('conflictResolutionModal.removeFlightNumbersConfirmationLabel'),
                                            confirmationAction: confirmationAction,
                                        }

                                        setConfirmationModalProps(confirmationModalProps)
                                        setConfirmationModalOpen(true)
                                    }}
                                >
                                    {t('conflictResolutionModal.resolveConflict')}
                                </Label>
                                <CustomTooltip
                                    arrow
                                    onClose={() => {}}
                                    onOpen={function noRefCheck() {}}
                                    placement="bottom"
                                    title={t('conflictResolutionModal.removeFlightNumbersToolTip')}
                                >
                                    <span className={styles.infoCircle}>i</span>
                                </CustomTooltip>
                            </>
                        )
                        break
                    }
                    default: {
                        conflictMessage = `${t('conflictResolutionModal.unexpectedErrorMessage')}`
                        const confirmationAction = async () => {
                            alert(`${t('conflictResolutionModal.unexpectedErrorMessage')}`)
                        }
                        tempConfirmationActions.push(confirmationAction)
                        actionButton = (
                            <button disabled={true} onClick={confirmationAction}>
                                {t('conflictResolutionModal.notApplicable')}
                            </button>
                        )
                        break
                    }
                }

                return {
                    surveyId,
                    description: (
                        <div key={surveyId}>
                            <Link to={`/edit-survey/${surveyId}`} target={'_blank'} className={styles.surveyNameLink}>
                                <Label
                                    title={
                                        <>
                                            {surveyName} <ExternalLinkIcon />
                                        </>
                                    }
                                    type="gray"
                                    color="500"
                                    lineHeight="28px"
                                    variant="h4"
                                    fontWeight="Light"
                                    className={styles.tableRowSurveyLabel}
                                />
                            </Link>
                            <Label
                                title={`${conflictMessage}${flightNumbers.join(', ')}`}
                                type="gray"
                                color="500"
                                lineHeight="28px"
                                variant="h5"
                                fontWeight="Light"
                                className={styles.tableRowSurveyConflictListLabel}
                            />
                        </div>
                    ),
                    action: actionButton,
                }
            })
            onConfirmationActionsDeclared(tempConfirmationActions)
            setTableData(tempTableData)
        }
        // TODO: when we will have the time to optimize code, we have to check the entire component tree to resolve linter rule below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usedConflicts, conflictResolvedCounter, surveyErrorMap])

    return (
        <>
            {!!tableData.length && (
                <TableBody>
                    {tableData.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell
                                style={i === tableData.length - 1 ? { borderBottom: 0 } : {}}
                                className={styles.conflictInfoTableCell}
                            >
                                <>
                                    {row.description}
                                    {!!surveyErrorMap[row.surveyId] && <div>{surveyErrorMap[row.surveyId]}</div>}
                                </>
                            </TableCell>
                            <TableCell
                                style={i === tableData.length - 1 ? { borderBottom: 0 } : {}}
                                className={styles.conflictResolutionButtonTableCell}
                            >
                                {row.action}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            )}
        </>
    )
}

export type TTableData = { surveyId: string; description: JSX.Element; action: JSX.Element }[]
export interface IConflictTable {
    usedConflicts: IDetailedConflictMap | undefined
    archiveSurvey: (surveyId: string) => Promise<ISurveyDto>
    setConfirmationModalErrorMessage: (errorMessage: string | JSX.Element) => void
    setConfirmationModalOpen: (isOpen: boolean) => void
    conflictResolvedCounter: number
    setConflictResolvedCounter: (counter: number) => void
    surveyErrorMap: TSurveyErrorMap
    setSurveyErrorMap: (surveyErrorMap: TSurveyErrorMap) => void
    setConfirmationModalProps: (confirmationModalProps: IConfirmationModalContentProps) => void
    refreshConflicts: () => Promise<void>
    onConfirmationActionsDeclared: (confirmationActions: TConfirmationAction[]) => void
}
