import { ExportHeader } from 'features/ExportSurvey'

import { AERQSurveyCreator } from './AERQSurveyCreator'

export const Page = () => {
    return (
        <div className="creator-container">
            <ExportHeader />
            <AERQSurveyCreator />
        </div>
    )
}
