import { Snackbar } from '@control-tower/aerq-ui-library'
import { SnackbarOrigin } from 'notistack'

export const customSuccessSnackbar = (_key: any, message: any) => {
    return <Snackbar message={message} variant="success" />
}

export const customErrorSnackbar = (_key: any, message: any) => {
    return <Snackbar message={message} variant="error" />
}

export const customAnchor: SnackbarOrigin = {
    horizontal: 'center',
    vertical: 'bottom',
}
