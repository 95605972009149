import { LoadingContainer } from 'components/loadingContainer'
import { SurveyListing } from 'features/SurveyListing'
import { useDebouncedCallback, usePaginator } from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'services/redux/hooks'
import { change } from 'services/redux/sortKeySlice'
import { useGetSurveyListingQuery } from 'services/surveyApi'
import { TSurveyStatus } from 'types'
import { SurveyType } from 'types/enums/SurveyType'
import { mapOrderByKeys, OrderNetworkParams } from 'utils/mapOrderByKeys'

export const Page = () => {
    const { t } = useTranslation()

    const resultsPerPage = 10
    const { offset, limit, page } = usePaginator(resultsPerPage)
    const params = useParams()
    const { status = 'active' } = params as { status: string }

    const navigate = useNavigate()

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [searchTermDebounce, setSearchTermDebounce] = useState<string>('')

    const sortKey = useAppSelector((state) => state.sortKey.value)
    const dispatch = useAppDispatch()

    const [orderParams, setOrderParams] = useState<OrderNetworkParams>(mapOrderByKeys(sortKey))

    const [surveyTypeFilter, setSurveyTypeFilter] = useState<SurveyType | undefined>(undefined)

    const { data, error, isLoading, isFetching } = useGetSurveyListingQuery({
        status,
        surveyType: surveyTypeFilter,
        offset,
        limit,
        technicalName: searchTermDebounce,
        orderConfig: orderParams,
    })

    const debouncedSearch = useDebouncedCallback((value) => {
        navigate({ search: 'p=1' }, { replace: true })
        setSearchTermDebounce(value)
    }, 500)

    return (
        <LoadingContainer isLoading={isLoading} error={error}>
            {data?.payload && (
                <SurveyListing
                    title={t('title.listingTitle')}
                    surveyList={data?.payload}
                    surveyCount={data?.meta.surveyCount}
                    isFetching={isFetching}
                    activePage={page}
                    resultsPerPage={resultsPerPage}
                    status={status as TSurveyStatus}
                    onSurveySearch={(e) => {
                        setSearchTerm(e.target.value)
                        debouncedSearch(e.target.value)
                    }}
                    onTabClick={(e) => {
                        if (searchTerm.length) {
                            setSearchTerm('')
                            setSearchTermDebounce('')
                        }
                    }}
                    onOrderByClick={(event) => {
                        const value = event?.target.value
                        value && dispatch(change(value))
                        value && setOrderParams(mapOrderByKeys(value))
                    }}
                    onFilterByClick={(event) => {
                        const value = event?.target.value as SurveyType
                        if (value) setSurveyTypeFilter(value)
                        else setSurveyTypeFilter(undefined)
                    }}
                    searchTerm={searchTerm}
                    currentCount={data?.meta.surveyCount.current}
                />
            )}
        </LoadingContainer>
    )
}
