import { Label } from '@control-tower/aerq-ui-library'
import { useTranslation } from 'react-i18next'

import styles from './AssignedFlightRulesBlock.module.css'
import { FlightRuleBlock } from './components/FlightRulesBlock'

interface Props {
    readonly: boolean
    isFullBlownSurvey: boolean
    onError: (error: any) => void
}

export const AssignedFlightRulesBlock = ({ readonly, isFullBlownSurvey, onError }: Props) => {
    const { t } = useTranslation()

    return (
        <>
            <div className={styles.titleContainer}>
                <Label color="900" type="grey" title={t('rules.assignedFlights')} style={{ marginBottom: '2rem' }} variant="h2" />
            </div>
            <FlightRuleBlock readonly={readonly} isFullBlownSurvey={isFullBlownSurvey} onError={onError} />
        </>
    )
}
