import { CustomButton, Label, Modal } from '@control-tower/aerq-ui-library'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyGetFlightRuleConflictsQuery } from 'services/ruleManifestApi'
import { useArchiveSurveyWithoutInvalidateMutation } from 'services/surveyApi'
import { IDetailedConflictMap } from 'types/interfaces/SurveyConflict'
import { customErrorSnackbar, customSuccessSnackbar } from 'utils/customSnackbarContent'

import { ConfirmationModal, IConfirmationModalContentProps, NullConfirmationModalContentProps } from './ConfirmationModal'
import { ConflictActions } from './ConflictActions'
import { ConflictCounterLabel } from './ConflictCounterLabel'
import { ConflictTable, TConfirmationAction } from './ConflictTable'
import styles from './RuleManifestConflictResolutionModal.module.css'
import { SuccessPage } from './SuccessPage'

export interface IRuleManifestConflictResolutionProps {
    conflict: IDetailedConflictMap | undefined
    isOpen: boolean
    hide: () => void
}

export type TSurveyErrorMap = {
    [key: string]: null | string | JSX.Element
}

export const RuleManifestConflictResolutionModal = ({ conflict, isOpen, hide }: IRuleManifestConflictResolutionProps) => {
    const { t } = useTranslation()
    const [usedConflicts, setUsedConflicts] = useState<IDetailedConflictMap | undefined>(conflict)
    const [archiveSurveyWithoutInvalidate] = useArchiveSurveyWithoutInvalidateMutation()
    const [getFlightRuleConflicts] = useLazyGetFlightRuleConflictsQuery()
    const [confirmationModalProps, setConfirmationModalProps] = useState<IConfirmationModalContentProps>(NullConfirmationModalContentProps)
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const [confirmationModalErrorMessage, setConfirmationModalErrorMessage] = useState<string | JSX.Element>('')
    const [conflictResolvedCounter, setConflictResolvedCounter] = useState(0)
    const [surveyErrorMap, setSurveyErrorMap] = useState<TSurveyErrorMap>({})
    const [confirmationActions, setConfirmationActions] = useState<TConfirmationAction[]>([])
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        setUsedConflicts(conflict)
    }, [conflict])

    const refreshConflicts = async () => {
        if (usedConflicts && usedConflicts.surveyToCheck.surveyId && usedConflicts.surveyToCheck.flightNumbers) {
            const newConflictResult = await getFlightRuleConflicts({
                surveyId: usedConflicts.surveyToCheck.surveyId,
                flightNumbers: usedConflicts?.surveyToCheck.flightNumbers,
            })
            if (newConflictResult.error) {
                throw new Error('Could not get results')
            }
            setUsedConflicts(newConflictResult.data)
        } else {
            // Optimization Idea: If There are no survey information to check, UI should hide the button.
            // Any warning to the user? There is no UX flow for this, so I added this until we can polish the APP.
            alert('There are no survey information to check.')
            console.warn('There are no survey information to check.', {
                surveyId: usedConflicts?.surveyToCheck?.surveyId,
                flightNumbers: usedConflicts?.surveyToCheck?.flightNumbers,
            })
        }
    }

    const refreshAction = async () => {
        try {
            await refreshConflicts()
            setConflictResolvedCounter(0)
            setSurveyErrorMap({})
            enqueueSnackbar(t('conflictResolutionModal.refreshListSuccess'), {
                content: customSuccessSnackbar,
            })
        } catch (e) {
            enqueueSnackbar(t('conflictResolutionModal.refreshListError'), {
                content: customErrorSnackbar,
            })
        }
    }

    const hideAction = async () => {
        hide()
    }

    return (
        <>
            <Modal
                className={styles.mainContainer}
                // @ts-ignore
                title={
                    <>
                        <Label
                            title={t('conflictResolutionModal.resolveConflicts')}
                            type="grey"
                            color="900"
                            lineHeight="28px"
                            variant="h4"
                            className={styles.headerTitle}
                        />
                        <hr style={{ borderTop: '1px solid #EEEEEE' }}></hr>
                    </>
                }
                open={isOpen}
                closeBtn={false}
                handleModalState={hideAction}
                maxWidth={1440}
                height={95}
                children={
                    <div className={styles.modalChildrenContainer}>
                        {usedConflicts?.surveyIdToFlightNumberMap && Object.keys(usedConflicts.surveyIdToFlightNumberMap).length ? (
                            <>
                                <div className={styles.conflictCounterAndActionsContainer}>
                                    <ConflictCounterLabel usedConflicts={usedConflicts} />
                                    <ConflictActions
                                        onRefresh={refreshAction}
                                        onResolveAll={async () => {
                                            if (confirmationActions.length) {
                                                for (const action of confirmationActions) {
                                                    // TODO: these actions needs to be run in useEffect, to be able to show all error messages when the BE is not available
                                                    // we only show the error on the conflict row that is run latest, because useState hooks are overwriting each other
                                                    await action()
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                {!!conflictResolvedCounter && (
                                    <div className={styles.conflictResolvedMessageContainer}>
                                        <Label
                                            className={styles.conflictResolvedMessage}
                                            type="primary"
                                            lineHeight="28px"
                                            variant="subtitle1"
                                            title={`${conflictResolvedCounter} ${
                                                conflictResolvedCounter === 1
                                                    ? t('conflictResolutionModal.conflictResolved')
                                                    : t('conflictResolutionModal.conflictsResolved')
                                            }`}
                                        />
                                    </div>
                                )}
                                <div className={styles.bodyContainer}>
                                    <ConflictTable
                                        usedConflicts={usedConflicts}
                                        archiveSurvey={async (surveyId: string) => archiveSurveyWithoutInvalidate(surveyId).unwrap()}
                                        setConfirmationModalErrorMessage={setConfirmationModalErrorMessage}
                                        setConfirmationModalOpen={setConfirmationModalOpen}
                                        conflictResolvedCounter={conflictResolvedCounter}
                                        setConflictResolvedCounter={setConflictResolvedCounter}
                                        surveyErrorMap={surveyErrorMap}
                                        setSurveyErrorMap={setSurveyErrorMap}
                                        setConfirmationModalProps={setConfirmationModalProps}
                                        refreshConflicts={refreshConflicts}
                                        onConfirmationActionsDeclared={setConfirmationActions}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className={styles.bodyContainer}>
                                <SuccessPage />
                            </div>
                        )}
                        <div className={styles.actionButtonContainer}>
                            <hr style={{ borderTop: '1px solid #EEEEEE', width: '100%', maxWidth: '1440px' }}></hr>
                            <div className={styles.actionButtonGroup}>
                                <CustomButton disabled={false} variant="outlined" color="primary" onClick={hideAction}>
                                    {usedConflicts?.surveyIdToFlightNumberMap && Object.keys(usedConflicts.surveyIdToFlightNumberMap).length
                                        ? t('conflictResolutionModal.cancel')
                                        : t('conflictResolutionModal.ok')}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                }
            />
            <ConfirmationModal
                isConfirmationModalOpen={isConfirmationModalOpen}
                setConfirmationModalOpen={setConfirmationModalOpen}
                setConfirmationModalErrorMessage={setConfirmationModalErrorMessage}
                confirmationModalProps={confirmationModalProps}
                confirmationModalErrorMessage={confirmationModalErrorMessage}
            />
        </>
    )
}
