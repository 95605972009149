import { defaultLanguage } from 'config/constants'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import deLocale from 'locales/de/common.json'
import enUsLocale from 'locales/en/common.json'
import { initReactI18next } from 'react-i18next'

export const startMultiLanguageSupport = () => {
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .use(LanguageDetector)
        .init({
            detection: {
                order: ['querystring', 'cookie', 'localStorage'],
                // keys or params to lookup language from
                lookupQuerystring: 'lang',
                lookupCookie: 'i18next',
                lookupLocalStorage: 'i18nextLng',
                // cache user language on
                caches: ['localStorage', 'cookie'],
                excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
            },
            resources: {
                en: {
                    translation: enUsLocale,
                },
                de: {
                    translation: deLocale,
                },
            },
            // lng: 'en', // if you're using a language detector, do not define the lng option
            fallbackLng: defaultLanguage,
            interpolation: {
                escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            },
        })
}
