import { DateField, IconButton, Label } from '@control-tower/aerq-ui-library'
import BackButtonIcon from '@control-tower/aerq-ui-library/dist/assets/svg/BackButtonIcon'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { TSurveyStatus } from 'types'
import { SurveyBuildingBlocks } from 'types/enums/SurveyBuildingBlocks'
import { SurveyTimeTriggers } from 'types/enums/SurveyTimeTriggers'
import { SurveyType } from 'types/enums/SurveyType'
import { ISurveyBackgroundImageDto } from 'types/interfaces/ISurveyBackgroundImageDto'
import { ISurveyTypeConfig } from 'types/interfaces/ISurveyTypeConfig'
import { IDetailedConflictMap } from 'types/interfaces/SurveyConflict'
import { customErrorSnackbar } from 'utils/customSnackbarContent'
import { formatDateWithFallback } from 'utils/formatDateWithFallback'
import { getDefaultDateFormatOptions } from 'utils/getDefaultDateFormatOptions'

import { ArchiveSurveyModal } from './components/ArchiveSurveyModal'
import { AssignedFlightRulesBlock } from './components/AssignedFlightRulesBlock'
import { BackgroundImageUploader } from './components/BackgroundImageUploader/BackgroundImageUploader'
import { DeleteSurveyModal } from './components/DeleteSurveyModal'
import { DetailsBlock } from './components/DetailsBlock/DetailsBlock'
import { DuplicateSurveyModal } from './components/DuplicateSurveyModal'
import { Header } from './components/Header'
import { PublishSurveyModal } from './components/PublishSurveyModal'
import { RuleManifestConflictResolutionModal } from './components/RuleManifestConflictResolutionModal'
import { TimeTriggerBlock } from './components/TimeTriggerBlock'
import styles from './EditSurveyPage.module.css'

export interface Props {
    surveyQuestionCount: number
    status: TSurveyStatus
    technicalName: string
    timeTrigger: SurveyTimeTriggers
    responseCount: number
    lastResponseDate: Date | null
    updatedAt: Date
    backgroundImage?: ISurveyBackgroundImageDto | null
    surveyTypeConfig: ISurveyTypeConfig
    supportedLanguages?: string[]
}

/**
 * Created this component to separate it from the data source which is coming from `Page.tsx` which acts as a Loader component
 */
export const EditSurveyPage = ({
    status,
    technicalName,
    timeTrigger,
    surveyQuestionCount,
    responseCount,
    lastResponseDate,
    updatedAt,
    backgroundImage,
    surveyTypeConfig,
    supportedLanguages,
}: Props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id }: { id?: string } = useParams()
    const surveyId = id || ''
    const isDraft = status === 'draft'
    const isActive = status === 'active'
    const isArchived = status === 'archived'
    const isFullBlown = surveyTypeConfig.surveyType === SurveyType.fullBlown

    const isTimeTriggerEditable = (isDraft || isActive) && isFullBlown
    const areRulesEditable = (isDraft || isActive) && isFullBlown

    const [isDuplicateSurveyModalOpen, setDuplicateSurveyModalOpen] = useState(false)
    const [isDeleteSurveyModalOpen, setDeleteSurveyModalOpen] = useState(false)
    const [isArchiveSurveyModalOpen, setArchiveSurveyModalOpen] = useState(false)
    const [isPublishSurveyModalOpen, setPublishSurveyModalOpen] = useState(false)
    const [isConflictResolutionModalOpen, setConflictResolutionModalOpen] = useState(false)
    const [surveyConflicts, setSurveyConflicts] = useState<IDetailedConflictMap | undefined>()
    const { enqueueSnackbar } = useSnackbar()

    supportedLanguages ||= ['default']

    const conflictResolutionErrorHandler = (error: any) => {
        if (error.status === 409) {
            const hasRuleManifestConflicts = !!Object.keys(error?.data?.conflicts?.flightNumberToSurveyIdMap ?? {}).length
            if (hasRuleManifestConflicts) {
                setSurveyConflicts(error.data.conflicts)
                setConflictResolutionModalOpen(true)
                setPublishSurveyModalOpen(false)
            } else {
                // handle other 409 errors here
                alert('ERROR 409: ' + error?.message)
            }
        } else if (error.status === 412) {
            enqueueSnackbar(t('error.emptyFlightRules'), {
                content: customErrorSnackbar,
            })
        } else {
            // handle other errors here
            console.error('conflictResolutionErrorHandler ERROR:', error)
        }
    }

    return (
        <>
            <IconButton label={t('basic.allSurveys')} icon={<BackButtonIcon />} onClick={() => navigate(`/home/${status}`)} />
            <div className={styles.headerWrapper}>
                <Header
                    technicalName={technicalName}
                    id={surveyId}
                    status={status}
                    surveyType={surveyTypeConfig.surveyType}
                    handleShowDuplicateSurveyModal={() => setDuplicateSurveyModalOpen(true)}
                    handleShowDeleteSurveyModal={() => setDeleteSurveyModalOpen(true)}
                    handleShowArchiveSurveyModal={() => setArchiveSurveyModalOpen(true)}
                    handleShowPublishSurveyModal={() => {
                        setPublishSurveyModalOpen(true)
                    }}
                />
            </div>
            {(isActive || isArchived) && (
                <div className={styles.blockWrapper}>
                    <DetailsBlock
                        title={t('basic.results')}
                        col1Label={t('basic.responses')}
                        col1Value={`${responseCount} ${t('basic.responses')}`}
                        col2Label={t('basic.lastResponse')}
                        col2Value={formatDateWithFallback(lastResponseDate?.toString() || '')}
                        buttonLink={`/analytics/${id}`}
                        buttonTitle={t('action.viewResults')}
                    />
                </div>
            )}
            <div className={styles.blockWrapper}>
                <DetailsBlock
                    title={t('title.surveyInformationTitle')}
                    col1Label={t('basic.questions')}
                    col1Value={surveyQuestionCount === 0 ? t('basic.noQuestions') : `${surveyQuestionCount} ${t('basic.questions')}`}
                    col2Label={t('basic.lastEdited')}
                    col2Value={
                        <DateField
                            date={updatedAt.toString()}
                            type="short"
                            dateTodayLabel={t('basic.today')}
                            preferences={getDefaultDateFormatOptions()}
                        />
                    }
                    col3Label={t('basic.surveyLanguagesTitle')}
                    col3Value={supportedLanguages?.map((lang) => t(`basic.languages.${lang}`)).join(', ')}
                    hasButton={isDraft}
                    buttonLink={`/survey-creator/${surveyId}`}
                    buttonTitle={t('action.editInSurveyCreator')}
                />
            </div>

            {
                <div className={styles.blockWrapper}>
                    <Label color="900" type="grey" title={t('title.inseatDisplayConfiguration')} variant="h2" />
                    {surveyTypeConfig.surveyType === SurveyType.onboarding && (
                        <div className={styles.timeTriggerOnboardingTable}>
                            <Label color="600" type="grey" title={t('timeTrigger.label')} variant="small" fontWeight="Medium" />
                            <Label color="800" type="grey" title={t('timeTrigger.onboardingTrigger')} variant="body" />
                        </div>
                    )}
                    {surveyTypeConfig.surveyType === SurveyType.rating && (
                        <div className={styles.timeTriggerOnboardingTable}>
                            <Label color="600" type="grey" title={t('timeTrigger.label')} variant="small" fontWeight="Medium" />
                            <Label color="800" type="grey" title={t('timeTrigger.ratingTrigger')} variant="body" />
                        </div>
                    )}
                    {surveyTypeConfig.buildingBlocks.includes(SurveyBuildingBlocks.timeTriggerPicker) && (
                        <TimeTriggerBlock surveyId={surveyId} readonly={!isTimeTriggerEditable} currentTimeTriggerValue={timeTrigger} />
                    )}
                    {surveyTypeConfig.buildingBlocks.includes(SurveyBuildingBlocks.backgroundPicker) && (
                        <div className={styles.backgroundImageUploaderWrapper}>
                            <BackgroundImageUploader readOnly={!isDraft} surveyId={surveyId} backgroundImage={backgroundImage} />
                        </div>
                    )}
                </div>
            }

            {surveyTypeConfig.buildingBlocks.includes(SurveyBuildingBlocks.flightAssignmentPicker) && (
                <div className={styles.blockWrapper}>
                    <AssignedFlightRulesBlock
                        readonly={!areRulesEditable}
                        isFullBlownSurvey={surveyTypeConfig.surveyType === SurveyType.fullBlown}
                        onError={conflictResolutionErrorHandler}
                    />
                </div>
            )}

            <DuplicateSurveyModal
                id={surveyId}
                initialInput={`${t('rules.copyof')} ${technicalName}`}
                isDuplicateSurveyModalOpen={isDuplicateSurveyModalOpen}
                hideDuplicateSurveyModal={() => setDuplicateSurveyModalOpen(false)}
            />
            <DeleteSurveyModal
                id={surveyId}
                isDeleteSurveyModalOpen={isDeleteSurveyModalOpen}
                hideDeleteSurveyModal={() => setDeleteSurveyModalOpen(false)}
            />
            <ArchiveSurveyModal id={surveyId} isOpen={isArchiveSurveyModalOpen} hideModal={() => setArchiveSurveyModalOpen(false)} />
            <RuleManifestConflictResolutionModal
                conflict={surveyConflicts}
                isOpen={isConflictResolutionModalOpen}
                hide={() => {
                    setConflictResolutionModalOpen(false)
                }}
            />
            <PublishSurveyModal
                id={surveyId}
                surveyType={surveyTypeConfig.surveyType}
                isOpen={isPublishSurveyModalOpen}
                hideModal={() => setPublishSurveyModalOpen(false)}
                onError={conflictResolutionErrorHandler}
            />
        </>
    )
}
