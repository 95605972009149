/* eslint-disable import/no-extraneous-dependencies */
// Inspired by https://basarat.gitbook.io/typescript/type-system/discriminated-unions#throw-in-exhaustive-checks

import { supportedLanguages } from 'config/constants'
import type { SurveyCreator } from 'survey-creator-react'
import { IGetSurveyResponseDto } from 'types/interfaces/IGetSurveyResponseDto'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertNever(_: never): never {
    throw new Error('Unexpected value. Should have been never.')
}

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()

export const creatorOptions = {
    showTestSurveyTab: true,
    showJSONEditorTab: true,
    showLogicTab: true,
    showTranslationTab: true,
    showState: true,
    questionTypes: ['rating', 'checkbox', 'imagepicker', 'ranking', 'boolean', 'dropdown', 'radiogroup'],
    haveCommercialLicense: true,
}

export const SurveyCreatorUtils = {
    creatorOptions: {
        default: creatorOptions,
        forRatingSurvey: {
            ...creatorOptions,
            questionTypes: ['boolean'],
            pageEditMode: 'single' as 'standard' | 'single' | 'bypage' | undefined, // Linter complained without type definition
        },
    },
    removeDuplicateButton: function (creatorInstance: SurveyCreator) {
        creatorInstance.onDefineElementMenuItems.add((_sender, options) => {
            const duplicateButtonIndex = options.items.findIndex((item: any) => item.id === 'duplicate')
            duplicateButtonIndex === -1 || options.items.splice(duplicateButtonIndex, 1)
        })
    },
    /**
     * On the survey settings, these values must be set to adhere to monokoto's desing
     */
    autoSelectFullBlownSurveyDefaultValues: function (creatorInstance: SurveyCreator) {
        creatorInstance.propertyGrid.setValue('pagePrevText', 'Back')
        creatorInstance.propertyGrid.setValue('showProgressBar', 'bottom')
        creatorInstance.propertyGrid.setValue('showQuestionNumbers', 'off')
        creatorInstance.propertyGrid.setValue('showPageTitles', 'false')
        creatorInstance.propertyGrid.setValue('showPageNumbers', 'false')
        creatorInstance.propertyGrid.setValue('showTitle', 'false')
    },

    autoSelectOnboardingSurveyDefaultValues: function (creatorInstance: SurveyCreator) {
        creatorInstance.propertyGrid.setValue('pagePrevText', 'Back')
        creatorInstance.propertyGrid.setValue('pageNextText', 'Continue')
        creatorInstance.propertyGrid.setValue('completeText', 'Finish')
        creatorInstance.propertyGrid.setValue('showProgressBar', 'bottom')
        creatorInstance.propertyGrid.setValue('showQuestionNumbers', 'off')
        creatorInstance.propertyGrid.setValue('showPageTitles', 'false')
        creatorInstance.propertyGrid.setValue('showPageNumbers', 'false')
        creatorInstance.propertyGrid.setValue('showTitle', 'false')
        creatorInstance.propertyGrid.setValue('showCompletedPage', 'false')
    },

    getLanguageArray: function (data: IGetSurveyResponseDto): string[] {
        const languages = getLanguageSetFromDefinition(data, supportedLanguages)
        return Array.from(languages)
    },
}

function getLanguageSetFromDefinition(definition: any, languages: string[]): Set<string> {
    if (typeof definition !== 'object' || definition === null) {
        return new Set()
    }

    const foundLanguages = new Set<string>(['default'])

    Object.keys(definition).forEach((key: string) => {
        if (languages.includes(key)) {
            foundLanguages.add(key)
        }
        if (typeof definition[key] === 'object') {
            const nestedLanguages = getLanguageSetFromDefinition(definition[key], languages)
            nestedLanguages.forEach((language) => foundLanguages.add(language))
        }
    })

    return foundLanguages
}

export const analyticsOptions = {
    haveCommercialLicense: true,
}

export const MAXIMUM_INDIVIDUAL_CHIPS = 2
