import { Select } from '@control-tower/aerq-ui-library'
import { SelectChangeEvent } from '@mui/material'
import { useUpdateFlightRule } from 'hooks/useUpdateFlightRule'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SurveyTimeTriggers } from 'types/enums/SurveyTimeTriggers'
import { customErrorSnackbar, customSuccessSnackbar } from 'utils/customSnackbarContent'

interface TimeTriggerSelectorProps {
    surveyId: string
    readonly: boolean
    currentTimeTriggerValue: SurveyTimeTriggers
    timeTriggerOptions: Array<{ id: string; name: string }>
}

export const TimeTriggerSelector = ({ surveyId, readonly, currentTimeTriggerValue, timeTriggerOptions }: TimeTriggerSelectorProps) => {
    const { t } = useTranslation()
    const { updateFlightRuleData } = useUpdateFlightRule(surveyId)
    const { enqueueSnackbar } = useSnackbar()

    const handleSaveTimeTrigger = useCallback(
        async (event: SelectChangeEvent<unknown>) => {
            try {
                const updatedRule = {
                    surveyId,
                    timeTrigger: event.target.value as SurveyTimeTriggers,
                }

                await updateFlightRuleData(undefined, updatedRule.timeTrigger)
                enqueueSnackbar(t('success.timeTriggerUpdated'), {
                    content: customSuccessSnackbar,
                })
            } catch (error) {
                console.error('Error updating flight rule:', error)
                enqueueSnackbar(t('error.timeTriggerUpdateError'), {
                    content: customErrorSnackbar,
                })
            }
        },
        [enqueueSnackbar, surveyId, t, updateFlightRuleData]
    )

    return (
        <Select
            variant="outlined"
            disabled={readonly}
            allowDeselect={false}
            label={t('timeTrigger.label')}
            values={timeTriggerOptions}
            value={currentTimeTriggerValue}
            onChange={handleSaveTimeTrigger}
        />
    )
}
