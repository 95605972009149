import { Modal } from '@control-tower/aerq-ui-library'

import { ModalContent } from '../../../../components/ModalContent'

export interface IConfirmationModalContentProps {
    title: string
    description: string
    confirmationAction: () => void
    confirmationActionLabel: string
}

export interface IConfirmationModalProps {
    isConfirmationModalOpen: boolean
    setConfirmationModalOpen: (isOpen: boolean) => void
    setConfirmationModalErrorMessage: (errorMessage: string | JSX.Element) => void
    confirmationModalProps: IConfirmationModalContentProps
    confirmationModalErrorMessage: string | JSX.Element
}

export const NullConfirmationModalContentProps: IConfirmationModalContentProps = {
    title: '',
    description: '',
    confirmationActionLabel: '',
    confirmationAction: () => {},
}

export const ConfirmationModal = ({
    isConfirmationModalOpen,
    setConfirmationModalOpen,
    setConfirmationModalErrorMessage,
    confirmationModalProps,
    confirmationModalErrorMessage,
}: IConfirmationModalProps) => {
    return (
        <Modal
            open={isConfirmationModalOpen}
            closeBtn={true}
            handleModalState={() => {
                setConfirmationModalOpen(false)
                setConfirmationModalErrorMessage('')
            }}
            maxWidth={600}
            children={
                <ModalContent
                    handleSubmit={confirmationModalProps.confirmationAction}
                    modalTitle={confirmationModalProps.title}
                    description={confirmationModalProps.description}
                    actionButtonLabel={confirmationModalProps.confirmationActionLabel}
                    isSubmitDisabled={false}
                    errorMessage={confirmationModalErrorMessage}
                    isError={!!confirmationModalErrorMessage}
                />
            }
        />
    )
}
