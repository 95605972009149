import { Label } from '@control-tower/aerq-ui-library'
import { SnackbarSuccessIcon } from '@control-tower/aerq-ui-library/dist/assets/svg'
import { useTranslation } from 'react-i18next'

import styles from './RuleManifestConflictResolutionModal.module.css'

export const SuccessPage = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.successPageContainer}>
            <div className={styles.successLogo}>
                <SnackbarSuccessIcon width={194} height={194} />
            </div>
            <Label
                title={t('conflictResolutionModal.allConflictsResolved')}
                type="grey"
                color="900"
                lineHeight="28px"
                variant="subtitle1"
                className={styles.allConflictsResolvedLabel}
            />
        </div>
    )
}
